"use client";
import { useTranslation } from "@/app/i18n/client";
import Drawer from "antd/es/drawer";
import SignInForm from "./signin-form";
import SignUpForm from "./signup-form";
import LogoutButton from "../../logout-button";
import { UserRound } from "lucide-react";
import { useAuthStore } from "@/src/stores/rootStoreProvider";
import { observer } from "mobx-react";
import { cx } from "@/src/utils/all";
import LanguageSwitcher from "../language-switcher";
import { X } from "lucide-react";
interface IProps {
  user: any;
  border?: boolean;
}

const ButtonProfile = observer(({ user, border = true }: IProps) => {
  const { t } = useTranslation("common");
  const authStore = useAuthStore();

  const onProfileClick = () => {
    authStore.setShowProfile(!authStore.showProfile);
  };

  const hideShowSignUp = () => {
    authStore.setShowSignUp(!authStore.showSignUpDrawer);
  };

  const hideShowSignIn = () => {
    authStore.setShowSignIn(!authStore.showSignInDrawer);
  };

  const signUpClick = () => {
    authStore.setShowSignIn(false);
    hideShowSignUp();
  };

  return (
    <>
      <span
        className={cx(
          "app-icon cursor-pointer rounded-full p-1",
          border ? "border" : "",
        )}
        onClick={user != null && user.name ? onProfileClick : hideShowSignIn}
      >
        <UserRound className="h-5 w-5" strokeWidth={1} />
      </span>

      {user != null && (
        <Drawer
          classNames={{ content: "!bg-surface-container" }}
          title={
            <div className="flex items-center justify-between">
              <LogoutButton label={t("btn.signout")} callbackUrl="/" />
              <div className="flex">
                <span className="text-right">
                  <h2 className="text-base font-semibold uppercase leading-none text-on-surface-container">
                    {user?.name}
                  </h2>
                  <p className="text-sm font-normal leading-tight text-on-surface-container">
                    {user?.email}
                  </p>
                </span>
                <span className="ml-2 rounded-full border border-slate-200 p-1">
                  <UserRound className="text-on-surface-container" />
                </span>
              </div>
            </div>
          }
          onClose={onProfileClick}
          open={authStore.showProfile}
          closable={false}
        >
          <div className="flex justify-between text-slate-500">
            {t("header.language")} <LanguageSwitcher showLabel={true} />
          </div>
        </Drawer>
      )}

      <Drawer
        onClose={hideShowSignUp}
        open={authStore.showSignUpDrawer}
        closable={false}
        classNames={{ content: "!bg-surface-container" }}
      >
        <div
          onClick={hideShowSignUp}
          className=" flex cursor-pointer items-start justify-end"
        >
          <X size={24} className="text-current" />
        </div>
        <SignUpForm />
      </Drawer>

      <Drawer
        onClose={hideShowSignIn}
        open={authStore.showSignInDrawer}
        closable={false}
        classNames={{ content: "!bg-surface-container" }}
      >
        <div
          onClick={hideShowSignIn}
          className=" flex cursor-pointer items-start justify-end"
        >
          <X size={24} className="text-current" />
        </div>
        <SignInForm onSignUpClick={signUpClick} />
      </Drawer>
    </>
  );
});

export default ButtonProfile;
