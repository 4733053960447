import(/* webpackMode: "eager" */ "/vercel/path0/portal/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/portal/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/portal/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/portal/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/portal/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/portal/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/portal/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/portal/node_modules/next/font/google/target.css?{\"path\":\"styles/fonts.ts\",\"import\":\"Be_Vietnam_Pro\",\"arguments\":[{\"variable\":\"--font-be-vn\",\"subsets\":[\"latin\"],\"weight\":\"400\",\"display\":\"swap\"}],\"variableName\":\"beVN\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/portal/src/components/app-items/cookie-banner.component.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/portal/src/components/layout-public/btn-profile/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/portal/src/components/layout-public/header/header.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/portal/src/components/icons/loading-dots.modules.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/portal/src/components/layout-public/language-switcher/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/portal/src/components/layout-public/main-input-search.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/portal/src/components/layout-public/nav/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/portal/src/components/layout-public/search.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/portal/src/images/logo.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/portal/src/theme/theme-switch.tsx");
