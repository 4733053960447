"use client";

import { LogOut } from "lucide-react";
import { signOut } from "next-auth/react";
import { useRouter } from "next/navigation";
import { useState } from "react";

export default function LogoutButton({ label, callbackUrl }: any) {
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(false);
  const doSignOut = async () => {
    setIsLoading(true);
    await signOut({ redirect: false, callbackUrl });
    setIsLoading(false);
    router.refresh();
  };

  if (label && label.length > 0) {
    return (
      <button
        onClick={doSignOut}
        className="border-slate-150 rounded-full border bg-surface-variant px-4 py-1 font-normal text-on-surface-variant transition-all duration-150 ease-in-out"
        disabled={isLoading}
      >
        {label}
      </button>
    );
  }
  return (
    <button
      onClick={() => signOut({ callbackUrl })}
      className="rounded-lg border-none bg-transparent p-1.5 text-on-surface-variant transition-all duration-150 ease-in-out"
      disabled={isLoading}
    >
      <LogOut width={18} />
    </button>
  );
}
