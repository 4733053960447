export const fallbackLng = "vi";
export const languages = ["en", "vi"];
export const defaultNS = "translation";
export const languageCookieName = "i18next";

export function getOptions(lng = fallbackLng, ns = defaultNS) {
  return {
    // debug: true,
    supportedLngs: languages,
    fallbackLng,
    lng,
    fallbackNS: defaultNS,
    defaultNS,
    ns,
  };
}
