const AppLogo = ({ size }: { size?: string | undefined }) => {
  const width = size || "100%";
  const height = size || "100%";
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 90 90"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M43 85C65.0914 85 83 67.0914 83 45C83 22.9086 65.0914 5 43 5C20.9086 5 3 22.9086 3 45C3 67.0914 20.9086 85 43 85ZM32.232 26.44C31.432 26.248 30.648 26.152 29.88 26.152V61H30.36C31.16 61 31.944 60.92 32.712 60.76C33.512 60.6 34.2 60.312 34.776 59.896C35.384 59.48 35.864 58.904 36.216 58.168C36.6 57.432 36.792 56.472 36.792 55.288V31.912C36.792 30.824 36.568 29.912 36.12 29.176C35.704 28.408 35.16 27.816 34.488 27.4C33.816 26.952 33.064 26.632 32.232 26.44ZM42.1099 26.152H41.5819V55.528C41.5819 56.552 41.7579 57.416 42.1099 58.12C42.4939 58.792 42.9739 59.352 43.5499 59.8C44.1579 60.216 44.8139 60.52 45.5179 60.712C46.2539 60.904 46.9899 61 47.7259 61H48.0139V31.768C48.0139 29.816 47.4379 28.392 46.2859 27.496C45.1659 26.6 43.7739 26.152 42.1099 26.152ZM52.4299 43C52.4939 43.352 52.5259 43.72 52.5259 44.104V55.336C52.5259 56.456 52.7179 57.384 53.1019 58.12C53.5179 58.824 54.0299 59.4 54.6379 59.848C55.2779 60.264 55.9659 60.568 56.7019 60.76C57.4699 60.92 58.2219 61 58.9579 61V42.28C58.9579 40.456 58.4459 39.064 57.4219 38.104C56.3979 37.112 54.9259 36.616 53.0059 36.616C51.7899 36.616 50.7499 36.952 49.8859 37.624C49.0219 38.296 48.5899 39.144 48.5899 40.168C48.5899 40.424 48.6059 40.68 48.6379 40.936C48.6699 41.16 48.7499 41.4 48.8779 41.656C49.1019 41.464 49.3419 41.336 49.5979 41.272C49.8859 41.176 50.1579 41.128 50.4139 41.128C50.8939 41.128 51.2619 41.224 51.5179 41.416C51.8059 41.576 52.0139 41.8 52.1419 42.088C52.3019 42.344 52.3979 42.648 52.4299 43Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default AppLogo;
