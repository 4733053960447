import i18next from "i18next";

export const validateMessages = {
  get required() {
    return i18next.t("error.REQUIRED_FIELD", { ns: "common" });
  },
  types: {
    get email() {
      return i18next.t("error.INVALID_FORMAT_${label}", { ns: "common" });
    },
    get number() {
      return i18next.t("error.${label}_INVALID_TYPE_${type}", { ns: "common" });
    },
    get float() {
      return i18next.t("error.${label}_INVALID_TYPE_${type}", { ns: "common" });
    },
    get regex() {
      return i18next.t("error.INVALID_FORMAT_${label}", { ns: "common" });
    },
    get date() {
      return i18next.t("error.INVALID_FORMAT_${label}", { ns: "common" });
    },
    get url() {
      return i18next.t("error.${label}_INVALID_TYPE_${type}", { ns: "common" });
    },
  },
  string: {
    get min() {
      return i18next.t("error.MIN_FIELD_LENGTH_${min}", { ns: "common" });
    },
    get max() {
      return i18next.t("error.MAX_FIELD_LENGTH_${max}", { ns: "common" });
    },
    get range() {
      return i18next.t(
        "error.${label}_FIELD_LENGTH_BETWEEN_${min}_AND_${max}",
        { ns: "common" },
      );
    }, //'${label} must be between ${min} and ${max}',
  },
  number: {
    get min() {
      return i18next.t("error.MIN_VALUE_${min}", { ns: "common" });
    },
    get max() {
      return i18next.t("error.MAX_VALUE_${max}", { ns: "common" });
    },
    get range() {
      return i18next.t("error.${label}_FIELD_VALUE_BETWEEN_${min}_AND_${max}", {
        ns: "common",
      });
    }, //'${label} must be between ${min} and ${max}',
  },
  date: {
    get format() {
      return i18next.t("error.INVALID_FORMAT_${label}", { ns: "common" });
    },
    get parse() {
      return i18next.t("error.INVALID_FORMAT_${label}", { ns: "common" });
    },
    get invalid() {
      return i18next.t("error.INVALID_FORMAT_${label}", { ns: "common" });
    },
  },
  pattern: {
    get mismatch() {
      return i18next.t("error.INVALID_FORMAT_${label}", { ns: "common" });
    },
  },
};

export const checkPasswordRetype = ({ getFieldValue }: any) => ({
  validator(rule: any, value: any) {
    if (!value || getFieldValue("password") === value) {
      return Promise.resolve();
    }
    return Promise.reject(
      i18next.t("error.PASSWORD_RETYPE_DO_NOT_MATCH", { ns: "common" }),
    ); // The two passwords that you entered do not match!'
  },
});
