"use client";
import { useTranslation } from "@/app/i18n/client";
import { useRef, useState } from "react";
import { usePathname, useRouter, useSearchParams } from "next/navigation";

interface IProps {}

function MainInputSearch({}: IProps) {
  const { t } = useTranslation("common");
  const inputRef = useRef<HTMLInputElement>(null);
  const pathname = usePathname();
  const router = useRouter();
  const searchParams = useSearchParams();
  const [search] = useState(searchParams.get("q") ?? "");

  return (
    <div className="input">
      <i className="icon-search"></i>

      <input
        ref={inputRef}
        type="text"
        placeholder={t("header.searchOnSite")}
        className="focus:ring-0"
        defaultValue={search}
        onKeyDown={async (e) => {
          if (e.key !== "Enter") {
            return;
          }

          if (pathname === "/" || pathname === "") {
            const { value } = e.currentTarget;
            router.push(`/search?q=${value}`);
          } else {
            router.push(`${pathname}?q=${inputRef.current?.value}`);
          }
        }}
      />

      <input
        className="secondary focus:ring-0"
        type="text"
        placeholder="or Google"
      />

      <input type="submit" />
    </div>
  );
}

export default MainInputSearch;
